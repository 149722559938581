import React, { useState } from "react";
import { apis } from "../apis";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Typography, CircularProgress } from "@mui/material";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();


  const validationSchema = Yup.object({
    password: Yup.string().required("كلمة المرور مطلوبة").min(8, "يجب أن تكون كلمة المرور 8 أحرف على الأقل"),
    confirmPassword: Yup.string()
      .required("تأكيد كلمة المرور مطلوب")
      .oneOf([Yup.ref("password")], "كلمتا المرور غير متطابقتين"),
  });

  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError("");

      try {
        const response = await apis.resetPassword( values.password);

        if (response.status === "success") {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          setError(response.msg || "فشل في إعادة تعيين كلمة المرور.");
        }
      } catch (err) {
        setError("حدث خطأ أثناء إعادة تعيين كلمة المرور.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }} dir="rtl">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
          <Typography variant="h4" align="center" gutterBottom>
            إعادة تعيين كلمة المرور
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="كلمة المرور الجديدة"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{ startAdornment: <FaLock style={{ marginRight: 10 }} /> }}
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="تأكيد كلمة المرور"
              name="confirmPassword"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              InputProps={{ startAdornment: <FaLock style={{ marginRight: 10 }} /> }}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              style={{ marginTop: "20px" }}
            >
              {loading ? <CircularProgress size={24} /> : "إعادة تعيين"}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
