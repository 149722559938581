import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthProvider"; // Import AuthContext
import Login from "./components/Login";
import CheckOTP from "./components/CheckOTP";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import CheckOTPResetPassword from "./components/CheckOTPResetPassword";
import Dashboard from "./components/Dashboard";
import AppLayout from "./components/AppLayout";
import "./App.css"
import Stories from "./components/Stories";
import StoryReviews from "./components/Reviews";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
          <Route path="/checkotp" element={<CheckOTP />} />
          <Route path="/checkotp-reset-password" element={<CheckOTPResetPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<AppLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stories" element={<Stories />} />
            <Route path="/reviews" element={<StoryReviews />} />
           </Route>
       
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
