import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import TopMenu from "./TopMenu";
import { apis } from "../apis"; // Import your API functions
import { AuthContext } from "../AuthProvider";

const AppLayout = () => {
  const [userData, setUserData] = useState(""); // State for the user's name
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);
  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = () => {
    // Perform logout logic
    localStorage.removeItem("token");
    navigate("/login");
  };

  const handleProfileClick = () => {
    // Navigate to the profile page
    navigate("/profile");
  };
  const handleHomeClick = () => {
    // Navigate to the profile page
    navigate("/dashboard");
  };

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await apis.getAdminData(); // Call the API
        console.log(response)
        if (response.status === "success") {
          setUserData(response.data[0]); // Update the user's name
        } else {
          console.error("Failed to fetch admin data:", response.message);
        }
      } catch (error) {
        console.error("Error fetching admin data:", error);
      } finally {
        setLoading(false); // Update loading state
      }
    };

    fetchAdminData();
  }, []);

  // Show loading indicator while fetching data
  if (loading) {
    return <div>Loading...</div>; // You can style this better
  }

  return (
    <div>
      {/* Top Menu */}
      <TopMenu
        userData={userData} // Pass the fetched name to the TopMenu
        onLogout={handleLogout}
        onProfileClick={handleProfileClick}
        onHomeClick={handleHomeClick}
      />

      {/* Dynamic Content */}
      <div style={{ paddingTop: "80px", padding: "20px" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default AppLayout;
