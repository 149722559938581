// src/apis.js
export const apis = {
  getAlbums: async (id) => {
    try {
   
      const response = await fetch("https://api.atfal.app/api/getAlbumsAdmin", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
  
        }
      });
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getStories: async (id) => {
    try {
   
      const response = await fetch("https://api.atfal.app/api/getStories?id="+id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
  
        }
      });
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getReviews: async (id) => {
    try {
   
      const response = await fetch("https://api.atfal.app/api/getReviews?id="+id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
  
        }
      });
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  editAlbum: async (id,name,color,image) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/editAlbum", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id,name,color,image
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error editing Album:", error);
      throw error;
    }
  },
  toggleStoryPremium: async (id,status) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/toggleStoryPremium", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id,status
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error editing Album:", error);
      throw error;
    }
  },
  getUsers: async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/getUsers", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
       
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error editing Album:", error);
      throw error;
    }
  },
  addAlbum: async (name,color,image) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/addAlbum", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name,color,image
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding Album:", error);
      throw error;
    }
  },
  addStory: async (name,image,longImage,storyFile,album) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/addStory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({name,image,longImage,storyFile,album}),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding Album:", error);
      throw error;
    }
  },
  editStory: async (id,name,image,longImage,storyFile) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/editStory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({id,name,image,longImage,storyFile}),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding Album:", error);
      throw error;
    }
  },
  deleteAlbum: async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/deleteAlbum", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding Album:", error);
      throw error;
    }
  },
  deleteStory: async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/deleteStory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error adding Album:", error);
      throw error;
    }
  },
  signup: async (name, email, password, address, bio, avatarFile) => {
    try {
      // First, upload the avatar and get the file path
      const avatarData = await apis.uploadFile(avatarFile);
      
      // Extract the file path from the uploaded avatar response
      const avatarFilePath = avatarData.filePath;

      // Now, send the signup request with the avatar file path
      const response = await fetch("https://api.atfal.app/api/admin_signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          name, 
          email, 
          password, 
          address, 
          bio, 
          avatar: avatarFilePath 
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  },
  signupStudent: async (name, email, password, address, idnum, reference) => {
    try {
      // First, upload the avatar and get the file path
      
      // Extract the file path from the uploaded avatar response

      // Now, send the signup request with the avatar file path
      const response = await fetch("https://api.atfal.app/api/student_signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          name, 
          email, 
          password, 
          address, 
          idnum, 
          reference 
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
  },

  updateAdminData: async (formData) => {


    console.log(formData)
    
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
      // First, upload the avatar and get the file path
      const avatarData = await apis.uploadFile(formData.avatar);
      
      // Extract the file path from the uploaded avatar response
      const avatarFilePath = avatarData.filePath;

      // Now, send the signup request with the avatar file path
      const response = await fetch("https://api.atfal.app/api/update-admin-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,

        },
        body: JSON.stringify({ 
          name:formData.name,
          address:formData.address, 
          bio:formData.bio, 
          avatar: avatarFilePath 
        }),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
    
  
  },

  updateStudentData: async (formData) => {


    console.log(formData)
    
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
      // First, upload the avatar and get the file path
      
      // Extract the file path from the uploaded avatar response

      // Now, send the signup request with the avatar file path
      const response = await fetch("https://api.atfal.app/api/update-student-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,

        },
        body: JSON.stringify({ 
          name:formData.name,
          address:formData.address, 
        }),
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error during signup:", error);
      throw error;
    }
    
  
  },

  checkOtp: async (email, otp) => {
    try {
      const response = await fetch("https://api.atfal.app/api/checkotp_admin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (data.status === "success") {
        localStorage.setItem("token", data.token);
      }
      return data;
    } catch (error) {
      console.error("Error checking OTP:", error);
      throw error;
    }
  },
  checkOtpStudent: async (email, otp) => {
    try {
      const response = await fetch("https://api.atfal.app/api/checkotp_student", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });
      const data = await response.json();
      if (data.status === "success") {
        localStorage.setItem("studenttoken", data.token);
      }
      return data;
    } catch (error) {
      console.error("Error checking OTP:", error);
      throw error;
    }
  },
  
  adminLogin: async (email, password) => {
    try {
      const response = await fetch("https://api.atfal.app/api/admin_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.status === "success") {
        localStorage.setItem("token", data.token);
      }
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  studentLogin: async (email, password) => {
    try {
      const response = await fetch("https://api.atfal.app/api/student_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.status === "success") {
        localStorage.setItem("studenttoken", data.token);
      }
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  redeemGiftCard: async (code) => {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    try {
      const response = await fetch("https://api.atfal.app/api/redeem_giftcard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify({ code }),
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  addClassQuestion: async (question,classId) => {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    try {
      const response = await fetch("https://api.atfal.app/api/student_add_class_question", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify({ question ,classId}),
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  
  addClassQuestionReport: async (question,reason) => {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    try {
      const response = await fetch("https://api.atfal.app/api/student_add_class_question_report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify({ question ,reason}),
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  addClassQuestionCommentReport: async (comment,reason) => {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    try {
      const response = await fetch("https://api.atfal.app/api/student_add_class_question_comment_report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify({ comment ,reason}),
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  
  addClassQuestionComment: async (question,comment) => {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    try {
      const response = await fetch("https://api.atfal.app/api/student_add_class_question_comment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,

        },
        body: JSON.stringify({ question ,comment}),
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  getClassQuestionComments: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/student_get_class_question_comments?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },

  getAdminData: async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/admin_data", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getLinkPreview: async (url) => {
    try {
     
      const response = await fetch("https://api.atfal.app/api/getLinkPreview?url="+url, {
        method: "GET",
       
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
    
  getAdminReferenceStudents: async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/admin_reference_students", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
    
  getAdminViews: async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/get_admin_views", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
    
  getStudentData: async () => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/student_data", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },

  addClassFileViews: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/addClassFileViews?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },

  addSubjectFileViews: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/addSubjectFileViews?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },

  addLessonFileViews: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/addLessonFileViews?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },

  isSubscriptionActive: async () => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/is_subscription_active", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getStudentSubscriptionData: async () => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/student_subscription_data", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getClassFiles: async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/getClassFiles_admin?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getClassFilesStudent: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/getClassFiles_student?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getSubjectFilesStudent: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/getSubjectFiles_student?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
  getLessonFilesStudent: async (id) => {
    try {
      const token = localStorage.getItem("studenttoken");
      if (!token) {
        return { status: "error", msg: "No token found" };
      }
      const response = await fetch("https://api.atfal.app/api/getLessonFiles_student?id="+id, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if(response.status==401){
        localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching admin data:", error);
      throw error;
    }
  },
    getSubjectFiles: async (id) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getSubjectFiles_admin?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getLessonFiles: async (id) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getLessonFiles_admin?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getClassQuizes: async (id) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getClassQuizes_admin?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getSubjectQuizes: async (id) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getSubjectQuizes_admin?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getLessonQuizes: async (id) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getLessonQuizes_admin?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getClassquizzesStudent: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getClassQuizes_student?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getSubjectquizzesStudent: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getSubjectQuizes_student?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getLessonquizzesStudent: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getLessonQuizes_student?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    studentTakeClassQuiz: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/student_class_quiz_taken?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    studentTakeSubjectQuiz: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/student_subject_quiz_taken?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    studentTakeLessonQuiz: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/student_lesson_quiz_taken?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getClassQuestions: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getClassQuestions?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getClassQuestion: async (id) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/getClassQuestion?id="+id, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getClasses: async () => {
      try {
      
        const response = await fetch("https://api.atfal.app/api/getClasses", {
          method: "GET",
          headers: {
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    getSubjects: async (id) => {
      try {
      
        const response = await fetch("https://api.atfal.app/api/getSubjects?id="+id, {
          method: "GET",
          headers: {
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    
    getLessons: async (id) => {
      try {
      
        const response = await fetch("https://api.atfal.app/api/getLessons?id="+id, {
          method: "GET",
          headers: {
          },
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching admin data:", error);
        throw error;
      }
    },
    
    forgotPassword: async (email) => {
      try {
        const response = await fetch("https://api.atfal.app/api/forgotpassword_admin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    forgotPasswordStudent: async (email) => {
      try {
        const response = await fetch("https://api.atfal.app/api/forgotpassword_student", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    saveClassFile: async (fileData) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/saveClassFile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(fileData),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    saveClassQuiz: async (name,quiz,classId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/saveClassQuiz", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,quiz,classId
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    saveSubjectQuiz: async (name,quiz,subjectId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/saveSubjectQuiz", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,quiz,subjectId
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    saveLessonQuiz: async (name,quiz,lessonId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/saveLessonQuiz", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,quiz,lessonId
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    editClassQuiz: async (name,quiz,classId,quizId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/editClassQuiz", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,quiz,classId,quizId
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    editSubjectQuiz: async (name,quiz,subjectId,quizId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/editSubjectQuiz", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,quiz,subjectId,quizId
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    editLessonQuiz: async (name,quiz,lessonId,quizId) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/editLessonQuiz", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name,quiz,lessonId,quizId
          }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    saveLessonFile: async (fileData) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/saveLessonFile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(fileData),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    saveSubjectFile: async (fileData) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/saveSubjectFile", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(fileData),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    resetPassword: async (password) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/resetpassword_admin", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ password }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    resetPasswordStudent: async (password) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/resetpassword_student", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ password }),
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    changePassword: async (passwordData) => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/admin_change_password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ oldPassword:passwordData.currentPassword,newPassword:passwordData.newPassword }),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    changePasswordStudent: async (passwordData) => {
      try {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        const response = await fetch("https://api.atfal.app/api/student_change_password", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ oldPassword:passwordData.currentPassword,newPassword:passwordData.newPassword }),
        });
        if(response.status==401){
          localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
        }
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error resetting password:", error);
        throw error;
      }
    },
    uploadFile: async (file) => {
        try {
          const formData = new FormData();
          formData.append("file", file); // Attach the file to the FormData object
          
          const response = await fetch("https://api.atfal.app/api/uploadr2", {
            method: "POST",
            body: formData,
          });
    
          const data = await response.json();
          if (response.ok) {
            return data; // Return the URL or file info
          } else {
            throw new Error(data.error || "Failed to upload file.");
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error; // Throw error to be handled by the calling component
        }
      },


      getSubjectQuestions: async (id) => {
        try {
          const token = localStorage.getItem("studenttoken");
          if (!token) {
            return { status: "error", msg: "No token found" };
          }
          const response = await fetch("https://api.atfal.app/api/getSubjectQuestions?id="+id, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error fetching admin data:", error);
          throw error;
        }
      },
      getSubjectQuestion: async (id) => {
        try {
          const token = localStorage.getItem("studenttoken");
          if (!token) {
            return { status: "error", msg: "No token found" };
          }
          const response = await fetch("https://api.atfal.app/api/getSubjectQuestion?id="+id, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error fetching admin data:", error);
          throw error;
        }
      },
      getSubjectQuestionComments: async (id) => {
        try {
          const token = localStorage.getItem("studenttoken");
          if (!token) {
            return { status: "error", msg: "No token found" };
          }
          const response = await fetch("https://api.atfal.app/api/student_get_subject_question_comments?id="+id, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error fetching admin data:", error);
          throw error;
        }
      },
      addSubjectQuestionComment: async (question,comment) => {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        try {
          const response = await fetch("https://api.atfal.app/api/student_add_subject_question_comment", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
    
            },
            body: JSON.stringify({ question ,comment}),
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error logging in:", error);
          throw error;
        }
      },
      addSubjectQuestionCommentReport: async (comment,reason) => {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        try {
          const response = await fetch("https://api.atfal.app/api/student_add_subject_question_comment_report", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
    
            },
            body: JSON.stringify({ comment ,reason}),
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error logging in:", error);
          throw error;
        }
      },
      addSubjectQuestionReport: async (question,reason) => {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        try {
          const response = await fetch("https://api.atfal.app/api/student_add_subject_question_report", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
    
            },
            body: JSON.stringify({ question ,reason}),
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error logging in:", error);
          throw error;
        }
      },
      addSubjectQuestion: async (question,subjectId) => {
        const token = localStorage.getItem("studenttoken");
        if (!token) {
          return { status: "error", msg: "No token found" };
        }
        try {
          const response = await fetch("https://api.atfal.app/api/student_add_subject_question", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
    
            },
            body: JSON.stringify({ question ,subjectId}),
          });
          if(response.status==401){
            localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error("Error logging in:", error);
          throw error;
        }
      },
      

getLessonQuestions: async (id) => {
  try {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    const response = await fetch("https://api.atfal.app/api/getLessonQuestions?id="+id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching admin data:", error);
    throw error;
  }
},
getLessonQuestion: async (id) => {
  try {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    const response = await fetch("https://api.atfal.app/api/getLessonQuestion?id="+id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching admin data:", error);
    throw error;
  }
},
getLessonQuestionComments: async (id) => {
  try {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    const response = await fetch("https://api.atfal.app/api/student_get_lesson_question_comments?id="+id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching admin data:", error);
    throw error;
  }
},
addLessonQuestionComment: async (question,comment) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_lesson_question_comment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ question ,comment}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
addLessonQuestionCommentReport: async (comment,reason) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_lesson_question_comment_report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ comment ,reason}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
addLessonQuestionReport: async (question,reason) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_lesson_question_report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ question ,reason}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
addLessonQuestion: async (question,lessonId) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_lesson_question", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ question ,lessonId}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
getClassMessages: async (id) => {
  try {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    const response = await fetch("https://api.atfal.app/api/getClassChat?id="+id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching admin data:", error);
    throw error;
  }
},
addClassMessageReport: async (msg,reason) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_class_message_report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ msg ,reason}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
getSubjectMessages: async (id) => {
  try {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    const response = await fetch("https://api.atfal.app/api/getSubjectChat?id="+id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching admin data:", error);
    throw error;
  }
},
addSubjectMessageReport: async (msg,reason) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_subject_message_report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ msg ,reason}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
getLessonMessages: async (id) => {
  try {
    const token = localStorage.getItem("studenttoken");
    if (!token) {
      return { status: "error", msg: "No token found" };
    }
    const response = await fetch("https://api.atfal.app/api/getLessonChat?id="+id, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching admin data:", error);
    throw error;
  }
},
addLessonMessageReport: async (msg,reason) => {
  const token = localStorage.getItem("studenttoken");
  if (!token) {
    return { status: "error", msg: "No token found" };
  }
  try {
    const response = await fetch("https://api.atfal.app/api/student_add_lesson_message_report", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,

      },
      body: JSON.stringify({ msg ,reason}),
    });
    if(response.status==401){
      localStorage.removeItem("studenttoken")
      window.location.href="/student/login?error=تم تسجيل الدخول على جهاز اخر, لذا تم تسجيل الخروج من هذا الجهاز"
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
},
  };
  