import React, { useState } from "react";
import { apis } from "../apis";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MdAlternateEmail } from "react-icons/md";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .required("البريد الالكتروني مطلوب")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError("");

      try {
        const response = await apis.forgotPassword(values.email);

        if (response.status === "success") {
          navigate("/checkotp-reset-password", { state: { email: values.email } });
        } else {
          setError(response.msg || "فشل في إرسال رمز OTP.");
        }
      } catch (err) {
        setError("حدث خطأ أثناء إرسال رمز التحقق.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }} dir="rtl">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
          <Typography variant="h4" align="center" gutterBottom>
            استعادة كلمة المرور
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="البريد الالكتروني"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{ startAdornment: <MdAlternateEmail style={{ marginRight: 10 }} /> }}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              style={{ marginTop: "20px" }}
            >
              {loading ? <CircularProgress size={24} /> : "إرسال رمز OTP"}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
