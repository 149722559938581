import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
  const [isAuthenticatedStudent, setIsAuthenticatedStudent] = useState(!!localStorage.getItem("studenttoken"));

  // Update `isAuthenticated` dynamically when the token changes
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("studenttoken");
    setIsAuthenticatedStudent(!!token);
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
  };
  const loginStudent = (token) => {
    localStorage.setItem("studenttoken", token);
    setIsAuthenticatedStudent(true);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  const logoutStudent = () => {
    localStorage.removeItem("studenttoken");
    setIsAuthenticatedStudent(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout,isAuthenticatedStudent,loginStudent,logoutStudent }}>
      {children}
    </AuthContext.Provider>
  );
};
