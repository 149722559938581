import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { apis } from "../apis"; // Ensure this imports your API functions
import { FaSmile, FaMeh, FaFrown } from "react-icons/fa"; // Importing icons from react-icons

ChartJS.register(Tooltip, Legend, ArcElement);

const StoryReviews = () => {
  const [searchParams] = useSearchParams();
  const storyId = searchParams.get("id");
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (storyId) {
      fetchReviews();
    }
  }, [storyId]);

  const fetchReviews = async () => {
    try {
      const response = await apis.getReviews(storyId); // Adjust this to match your API
      setReviews(response.data);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError("فشل في جلب المراجعات.");
      setLoading(false);
    }
  };

  if (loading) return <p>جارٍ التحميل...</p>;
  if (error) return <p>{error}</p>;

  // Prepare data for the table
  const rows = reviews.map((review, index) => ({ id: index + 1, ...review }));

  // Function to get smiley icons for review values
  const getSmileyIcon = (value) => {
    switch (value) {
      case "good":
        return <FaSmile color="#68a357" size={40}/>; // Green smiley face for "good"
      case "notBad":
        return <FaMeh color="#da9b2b"  size={40}/>; // Yellow neutral face for "not bad"
      case "notGood":
        return <FaFrown color="#bf644f"  size={40}/>; // Red frown face for "not good"
      default:
        return null;
    }
  };

  const columns = [
    { field: "id", headerName: "الرقم", width: 70 },
    { field: "content", headerName: "مراجعة الفحوى والمضمون", width: 200, renderCell: (params) => getSmileyIcon(params.value) },
    { field: "style", headerName: "مراجعة الأداء", width: 200, renderCell: (params) => getSmileyIcon(params.value) },
    { field: "sound", headerName: "مراجعة المؤثرات الصوتية", width: 200, renderCell: (params) => getSmileyIcon(params.value) },
    { field: "music", headerName: "مراجعة الموسيقى", width: 200, renderCell: (params) => getSmileyIcon(params.value) },
  ];

  // Function to calculate review counts for a specific field
  const calculateReviewCounts = (field) => {
    const counts = { good: 0, notBad: 0, notGood: 0 };
    reviews.forEach((review) => {
      if (counts[review[field]] !== undefined) {
        counts[review[field]]++;
      }
    });
    return counts;
  };

  // Chart data for each field
  const createChartData = (counts) => ({
    labels: ["جيد", "لا بأس", "غير جيد"],
    datasets: [
      {
        data: [counts.good, counts.notBad, counts.notGood],
        backgroundColor: ["#68a357", "#da9b2b", "#bf644f"],
      },
    ],
  });

  // Calculate counts for each review type
  const contentCounts = calculateReviewCounts("content");
  const styleCounts = calculateReviewCounts("style");
  const soundCounts = calculateReviewCounts("sound");
  const musicCounts = calculateReviewCounts("music");

  return (
    <div style={{ padding: "20px", direction: "rtl", textAlign: "right" }}>
      <h1>المراجعات للقصة رقم: {storyId}</h1>

      <div style={{ height: 400, marginBottom: "20px" }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} sx={{

"& .MuiDataGrid-cell": {
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .MuiDataGrid-columnHeader": {
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    textAlign:"center"
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#f5f5f5",
  },
        }}/>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "20px",
        }}
      >
        <div>
          <h3 style={{
            textAlign:"center"
          }}>مراجعات الفحوى والمضمون</h3>
          <Pie data={createChartData(contentCounts)} />
        </div>
        <div>
          <h3 style={{
            textAlign:"center"
          }}>مراجعات الأداء</h3>
          <Pie data={createChartData(styleCounts)} />
        </div>
        <div>
          <h3 style={{
            textAlign:"center"
          }}>مراجعات المؤثرات الصوتية</h3>
          <Pie data={createChartData(soundCounts)} />
        </div>
        <div>
          <h3 style={{
            textAlign:"center"
          }}>مراجعات الموسيقى</h3>
          <Pie data={createChartData(musicCounts)} />
        </div>
      </div>
    </div>
  );
};

export default StoryReviews;
