import React, { useContext, useState } from "react";
import { apis } from "../apis"; // Import the API functions
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Typography, CircularProgress } from "@mui/material";
import {  FaLock } from "react-icons/fa"; // Icons for input fields
import { useNavigate } from "react-router-dom"; // For navigation
import { AuthContext } from "../AuthProvider";
import { MdAlternateEmail } from "react-icons/md";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    password: Yup.string().required("كلمة المرور مطلوبة").min(6, "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل"),
  });

  // Formik Form
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError("");

      try {
        // Call the login API
        const response = await apis.adminLogin(values.email, values.password);

        if (response.status === "success") {
            login(response.token);

          // If login is successful, navigate to the dashboard
          navigate("/dashboard");
        } else {
          setError(response.msg || "بيانات الاعتماد غير صالحة.");
        }
      } catch (err) {
        console.log(err)
        setError("حدث خطأ أثناء تسجيل الدخول.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }} dir="rtl">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
          <Typography variant="h4" align="center" gutterBottom>
            تسجيل الدخول
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            {/* Email Input */}
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="البريد الالكتروني"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{ startAdornment: <MdAlternateEmail style={{ marginRight: 10 }} /> }}
            />

            {/* Password Input */}
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="كلمة المرور"
              name="password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{ startAdornment: <FaLock style={{ marginRight: 10 }} /> }}
            />

            {error && <Typography color="error" style={{ marginTop: "10px" }}>{error}</Typography>}

            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              style={{ marginTop: "20px" }}
            >
              {loading ? <CircularProgress size={24} /> : "تسجيل الدخول"}
            </Button>
          </form>


          <Typography align="center" style={{ marginTop: "10px", color: "#666" }}>
            نسيت كلمة المرور؟{" "}
            <Button variant="text" color="primary" onClick={() => navigate("/forgot-password")} style={{ padding: "0" }}>
              استعادة كلمة المرور
            </Button>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
