import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDropzone } from "react-dropzone";
import { apis } from "../apis"; // Assuming you have a way to fetch data from your backend
import { useNavigate, useLocation } from "react-router-dom";
import { Switch } from "@mui/material";

const Stories = () => {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(""); // To show validation errors
  const [album, setAlbum] = useState(null); // Track the album data
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // Modal visibility state for adding new story
  const [newStory, setNewStory] = useState({ name: "", image: null, longImage: null, storyFile: null }); // State for new story
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); // State for delete confirmation dialog
  const [storyToDelete, setStoryToDelete] = useState(null); // State to track the story being deleted
  const navigate = useNavigate();
  const location = useLocation();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal visibility state for editing a story
  const [storyToEdit, setStoryToEdit] = useState(null); // Track the story being edited
   // Function to toggle premium status
   const onTogglePremium = async (storyId, currentStatus) => {
    try {
      // Call the API to update premium status
      const response = await apis.toggleStoryPremium(storyId, !currentStatus);
      console.log("Premium status updated:", response);

      // Update the story list locally
      setStories((prevStories) =>
        prevStories.map((story) =>
          story.id === storyId ? { ...story, premium: !currentStatus } : story
        )
      );
    } catch (error) {
      console.error("Error toggling premium status:", error);
    }
  };
  // Open modal for editing a story
  const handleEditOpen = (story) => {
    setStoryToEdit(story);
    setNewStory({
      name: story.name,
      image: story.image,
      longImage: story.longImage,
      storyFile: story.story,
    });
    setIsEditModalOpen(true);
  };
  
  // Close edit modal
  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setStoryToEdit(null);
  };
  
  // Save edited story
  const handleEditStory = async () => {
    if (!newStory.name || !newStory.image || !newStory.longImage || !newStory.storyFile) {
      setError("الرجاء إدخال جميع البيانات");
      return;
    }
  
    try {
      const response = await apis.editStory(storyToEdit.id,
         newStory.name,
        newStory.image,
         newStory.longImage,
        newStory.storyFile,
      );
      console.log(response);
      setIsEditModalOpen(false);
      fetchStories(storyToEdit.album); // Refresh stories
    } catch (e) {
      console.error("Error updating story:", e);
      setError("فشل تعديل القصة");
    }
  };
  
  const fetchStories = async (albumId) => {
    try {
      const data = await apis.getStories(albumId); // Fetch stories based on album id
      setStories(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching stories:", error);
      setLoading(false);
      setError("فشل في تحميل القصص");
    }
  };

  // Fetch album and stories data based on the album ID from the query params
  useEffect(() => {
    const albumId = new URLSearchParams(location.search).get("id");
    if (albumId) {
      fetchStories(albumId);
    }
  }, [location.search]);

  // Open modal for adding new story
  const handleAddOpen = () => {
    setNewStory({ name: "", image: null, longImage: null, storyFile: null });
    setIsAddModalOpen(true);
  };

  // Close modals
  const handleAddClose = () => {
    setIsAddModalOpen(false);
  };

  // Handle file drop for image and longImage
  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async(acceptedFiles) => {
      

      const response=await apis.uploadFile(acceptedFiles[0])
      console.log(response)
      
      setNewStory((prev) => ({ ...prev, image: response.url }))
    
    },
  });

  const { getRootProps: getLongImageRootProps, getInputProps: getLongImageInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async(acceptedFiles) => {
      

      const response=await apis.uploadFile(acceptedFiles[0])
      console.log(response)
      
      setNewStory((prev) => ({ ...prev, longImage: response.url }))
    
    },
  });

  const { getRootProps: getStoryFileRootProps, getInputProps: getStoryFileInputProps } = useDropzone({
    accept: ".mp3",
    onDrop:async (acceptedFiles) => {
      

      const response=await apis.uploadFile(acceptedFiles[0])
      console.log(response)
      
      setNewStory((prev) => ({ ...prev, storyFile: response.url }))
      setNewStory((prev) => ({ ...prev, storyFileName: acceptedFiles[0].name }))
    
    },
  });

  // Add new story
  const handleAddStory = async () => {
    const albumId = new URLSearchParams(location.search).get("id");
    if (!newStory.name || !newStory.image || !newStory.longImage || !newStory.storyFile) {
      setError("الرجاء إدخال جميع البيانات");
      return;
    }

  

    try{
 const response = await apis.addStory(newStory.name,newStory.image,newStory.longImage,newStory.storyFile,albumId);
    console.log(response)
    setIsAddModalOpen(false)
    fetchStories(albumId)
    }
    catch(e){

      console.error(e)
    }
   


    // try {
    //   const response = await apis.addStory(formData); // Assuming your API supports form-data upload
    //   fetchStories(response.albumId); // Refresh stories after adding a new one
    //   setIsAddModalOpen(false);
    // } catch (error) {
    //   console.error("Error adding story:", error);
    //   setError("فشل إضافة القصة");
    // }
  };

  // Handle story deletion
  const handleDeleteConfirmation = (story) => {
    setStoryToDelete(story);
    setDeleteConfirmationOpen(true);
  };

  // Confirm delete action
  const handleDelete = async () => {
    if (storyToDelete) {
      try {
        await apis.deleteStory(storyToDelete.id);
        fetchStories(storyToDelete.album); // Refresh the stories list after deletion
        setDeleteConfirmationOpen(false);
        setStoryToDelete(null);
      } catch (error) {
        console.error("Error deleting story:", error);
        setError("فشل حذف القصة");
      }
    }
  };

  // Close delete confirmation dialog
  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false);
    setStoryToDelete(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const columns = [
    { field: "id", headerName: "#", width: 100 },
    { field: "name", headerName: "اسم القصة", width: 200 },
    {
      field: "image",
      headerName: "الصورة",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Story"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "cover",
            borderRadius: "20px",
          }}
        />
      ),
    },
    {
      field: "longImage",
      headerName: "صورة الخلفية",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Story"
          style={{
            width: "100px",
            height: "100px",
            objectFit: "contain",
            borderRadius: "20px",
          }}
        />
      ),
    },
    {
      field: "story",
      headerName: "القصة",
      width: 300,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          مشاهدة القصة
        </a>
      ),
    },
    {
      field: "premium",
      headerName: "مميز",
      width: 150,
      renderCell: (params) => (
        <Switch
          checked={params.row.premium}
          onChange={() => onTogglePremium(params.row.id, params.row.premium)}
          color="primary"
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "الإجراءات",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          label="التقييمات"
          onClick={() => navigate("/reviews?id=" + params.row.id)}
          showInMenu
        />,
        <GridActionsCellItem
          label="تعديل"
          onClick={() => handleEditOpen(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="حذف"
          onClick={() => handleDeleteConfirmation(params.row)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <div dir="rtl" style={{ height: 600, width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        القصص
      </Typography>
      <Button
        onClick={handleAddOpen}
        variant="contained"
        color="primary"
        style={{ marginBottom: "20px" }}
      >
        إضافة قصة جديدة
      </Button>
      <DataGrid
        rows={stories}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        rowHeight={120}
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-cell": {
            textAlign: "right",
            direction: "rtl",
          },
          "& .MuiDataGrid-columnHeader": {
            textAlign: "right",
            direction: "rtl",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      />
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>

      {/* Modal for adding new story */}
      <Dialog open={isAddModalOpen} onClose={handleAddClose}>
        <DialogTitle>إضافة قصة جديدة</DialogTitle>
        <DialogContent>
          <TextField
            label="اسم القصة"
            fullWidth
            margin="normal"
            value={newStory.name}
            onChange={(e) =>
              setNewStory((prev) => ({ ...prev, name: e.target.value }))
            }
          />

          {/* Image Dropzone */}
          <div style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer", marginBottom: "10px" }}>
            <Box {...getImageRootProps()}>
              <input {...getImageInputProps()} />
              <Typography>إسحب الصورة هنا أو اختر من جهازك</Typography>
            </Box>
            {newStory.image && (
              <Box>
                <Typography>تم اختيار الصورة:</Typography>
                <img
                  src={newStory.image}
                  alt="Story Image Preview"
                  style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }}
                />
              </Box>
            )}
          </div>

          {/* Long Image Dropzone */}
          <div style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer", marginBottom: "10px" }}>
            <Box {...getLongImageRootProps()}>
              <input {...getLongImageInputProps()} />
              <Typography>إسحب صورة الخلفية هنا أو اختر من جهازك</Typography>
            </Box>
            {newStory.longImage&& (
              <Box>
                <Typography>تم اختيار صور الخلفية:</Typography>
                <img
                    src={newStory.longImage}
                    alt={`Long Image Preview`}
                    style={{ width: "100px", height: "100px", objectFit: "contain", marginTop: "10px" }}
                  />
              </Box>
            )}
          </div>

          {/* Story File Dropzone */}
          <div style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer", marginBottom: "10px" }}>
            <Box {...getStoryFileRootProps()}>
              <input {...getStoryFileInputProps()} />
              <Typography>إسحب ملف القصة (MP3) هنا أو اختر من جهازك</Typography>
            </Box>
            {newStory.storyFile && (
              <Box>
                <Typography>تم اختيار ملف القصة:</Typography>
                <Typography>{newStory.storyFileName}</Typography>
              </Box>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClose} color="primary">
            إغلاق
          </Button>
          <Button onClick={handleAddStory} color="primary">
            إضافة القصة
          </Button>
        </DialogActions>
      </Dialog>
<Dialog open={isEditModalOpen} onClose={handleEditClose}>
  <DialogTitle>تعديل القصة</DialogTitle>
  <DialogContent>
    <TextField
      label="اسم القصة"
      fullWidth
      margin="normal"
      value={newStory.name}
      onChange={(e) =>
        setNewStory((prev) => ({ ...prev, name: e.target.value }))
      }
    />

    {/* Image Dropzone */}
    <div style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer", marginBottom: "10px" }}>
      <Box {...getImageRootProps()}>
        <input {...getImageInputProps()} />
        <Typography>إسحب الصورة هنا أو اختر من جهازك</Typography>
      </Box>
      {newStory.image && (
        <Box>
          <Typography>تم اختيار الصورة:</Typography>
          <img
            src={newStory.image}
            alt="Story Image Preview"
            style={{ width: "100px", height: "100px", objectFit: "cover", marginTop: "10px" }}
          />
        </Box>
      )}
    </div>

    {/* Long Image Dropzone */}
    <div style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer", marginBottom: "10px" }}>
      <Box {...getLongImageRootProps()}>
        <input {...getLongImageInputProps()} />
        <Typography>إسحب صورة الخلفية هنا أو اختر من جهازك</Typography>
      </Box>
      {newStory.longImage && (
        <Box>
          <Typography>تم اختيار صور الخلفية:</Typography>
          <img
            src={newStory.longImage}
            alt="Long Image Preview"
            style={{ width: "100px", height: "100px", objectFit: "contain", marginTop: "10px" }}
          />
        </Box>
      )}
    </div>

    {/* Story File Dropzone */}
    <div style={{ border: "1px dashed #ccc", padding: "10px", cursor: "pointer", marginBottom: "10px" }}>
      <Box {...getStoryFileRootProps()}>
        <input {...getStoryFileInputProps()} />
        <Typography>إسحب ملف القصة هنا أو اختر من جهازك</Typography>
      </Box>
      {newStory.storyFile && (
        <Box>
          <Typography>تم اختيار ملف القصة: {newStory.storyFileName}</Typography>
        </Box>
      )}
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleEditClose} color="secondary">
      إلغاء
    </Button>
    <Button onClick={handleEditStory} color="primary">
      حفظ التعديلات
    </Button>
  </DialogActions>
</Dialog>
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancel}>
        <DialogTitle>تأكيد الحذف</DialogTitle>
        <DialogContent>
          <Typography>هل أنت متأكد من حذف هذه القصة؟</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            إلغاء
          </Button>
          <Button onClick={handleDelete} color="secondary">
            حذف
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Stories;
