import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box } from "@mui/material";
import { MdAccountCircle } from "react-icons/md";

const TopMenu = ({ userData, onLogout, onProfileClick,onHomeClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="primary" style={{ backgroundColor: "#567C8D" }} dir="rtl">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          أهلاً وسهلاً، {userData.name || "ضيف"}
        </Typography>

        <Box>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={handleMenuOpen}
            aria-controls="user-menu"
            aria-haspopup="true"
          >
            {

                userData.avatar ?<img
                src={"https://pub-de9dc6e47f7c429ca4b7cd5f036cdacb.r2.dev/"+userData.avatar}
                style={{
                    width:"50px",
                    height:"50px",
                    borderRadius:"50%"
                }}
                />:<MdAccountCircle />
            }

</IconButton>

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
               <MenuItem onClick={() => { handleMenuClose(); onHomeClick(); }}>
              الرئيسية
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); onLogout(); }}>
              تسجيل الخروج
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
