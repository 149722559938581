import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useDropzone } from "react-dropzone";
import { apis } from "../apis"; // Assuming you have a way to fetch data from your backend
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [albums, setAlbums] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(""); // To show validation errors
  const [editingAlbum, setEditingAlbum] = useState(null); // Track the album being edited
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal visibility state for editing
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // Modal visibility state for adding new album
  const [newAlbum, setNewAlbum] = useState({ name: "", color: "", image: "" }); // State for new album
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false); // State for delete confirmation dialog
  const [albumToDelete, setAlbumToDelete] = useState(null); // State to track the album being deleted
  const navigate = useNavigate();

  const fetchAlbums = async () => {
    try {
      const data = await apis.getAlbums();
      setAlbums(data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching albums:", error);
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const data = await apis.getUsers();
      const groupedUsers = data.data.reduce((acc, kid) => {
        const userIndex = acc.findIndex((user) => user.user_id === kid.user_id);
        if (userIndex > -1) {
          acc[userIndex].kids.push({
            kid_id: kid.kid_id,
            kid_name: kid.kid_name,
            gender: kid.gender,
            kid_dob: new Date(kid.kid_dob).toLocaleDateString(),
          });
        } else {
          acc.push({
            user_id: kid.user_id,
            user_name: kid.user_name,
            email: kid.email,
            last_login: new Date(kid.last_login).toLocaleString(),
            kids: [
              {
                kid_id: kid.kid_id,
                kid_name: kid.kid_name,
                gender: kid.gender,
                kid_dob: new Date(kid.kid_dob).toLocaleDateString(),
              },
            ],
          });
        }
        return acc;
      }, []);
      setUsers(groupedUsers);

      console.log(groupedUsers)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching albums:", error);
      setLoading(false);
    }
  };

  // Fetch album data
  useEffect(() => {
    fetchAlbums();
    fetchUsers()
  }, []);

  // Open modal for editing
  const handleEditOpen = (album) => {
    setEditingAlbum({ ...album });
    setIsEditModalOpen(true);
  };

  // Open modal for adding new album
  const handleAddOpen = () => {
    setNewAlbum({ name: "", color: "", image: "" });
    setIsAddModalOpen(true);
  };

  // Close modals
  const handleEditClose = () => {
    setIsEditModalOpen(false);
    setEditingAlbum(null);
  };

  const handleAddClose = () => {
    setIsAddModalOpen(false);
  };

  // Save changes from editing modal
  const handleSave = async () => {
    if (editingAlbum.color && !validateColor(editingAlbum.color)) {
      setError("يجب أن تكون الألوان بتنسيق صحيح وأن تحتوي على لونين على الأقل.");
      return;
    }

    setAlbums((prev) =>
      prev.map((album) =>
        album.id === editingAlbum.id ? { ...editingAlbum } : album
      )
    );
    const response = await apis.editAlbum(editingAlbum.id, editingAlbum.name, editingAlbum.color, editingAlbum.image);
    handleEditClose();
  };

  // Add new album
  const handleAddAlbum = async () => {
    if (!newAlbum.name || !newAlbum.color) {
      setError("الرجاء إدخال اسم الألبوم والألوان");
      return;
    }

    if (newAlbum.color && !validateColor(newAlbum.color)) {
      setError("يجب أن تكون الألوان بتنسيق صحيح وأن تحتوي على لونين على الأقل.");
      return;
    }

    try {
      const response = await apis.addAlbum(newAlbum.name, newAlbum.color, newAlbum.image);
      fetchAlbums();
      setIsAddModalOpen(false);
    } catch (error) {
      console.error("Error adding album:", error);
      setError("فشل إضافة الألبوم");
    }
  };

  // Validation for CSV hex colors
  const validateColor = (value) => {
    const colors = value.split(",");
    if (colors.length < 2) return false; // At least two colors required
    const hexColorRegex = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;
    return colors.every((color) => hexColorRegex.test(color.trim()));
  };

  // Handle image upload in modal
  const handleImageUpload = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    try {
      // Upload the file to the server
      const response = await apis.uploadFile(file);
      const imageUrl = response.url; // Assuming the response contains the URL

      // Update the album with the uploaded image URL
      setEditingAlbum((prev) => ({ ...prev, image: imageUrl }));
      setNewAlbum((prev) => ({ ...prev, image: imageUrl })); // For new album
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("فشل تحميل الصورة");
    }
  };

  // Image upload dropzone
  const ImageDropzone = () => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: handleImageUpload,
    });

    return (
      <div
        {...getRootProps()}
        style={{
          border: "1px dashed #ccc",
          padding: "10px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body2">اسحب وأفلت أو انقر لتحميل صورة</Typography>
      </div>
    );
  };

  // Define table columns
  const columns = [

    { field: "id", headerName: "#", width: 50 },

    {
      field: "image",
      headerName: "الصورة",
      width: 150,
      renderCell: (params) => (
        <div style={{
          width:"100%",
          height:"100%",
          display:"flex",
          justifyContent:"center",
          alignItems:"center"
        }}>
        <img
          src={params.value}
          alt="Album"
          style={{ width: "100px", height: "100px", objectFit: "cover",borderRadius:"20px" }}
        />
        </div>
       
      ),
    },
    { field: "name", headerName: "الاسم", width: 200 },
    { field: "color", headerName: "الألوان", width: 200 },
    { field: "item_count", headerName: "عدد العناصر", width: 150 },
    {
      field: "actions",
      type: "actions",
      headerName: "الإجراءات",
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          label="القصص"
          onClick={() => {
          
            navigate("/stories?id="+params.row.id)
          
          }}
          showInMenu
        />,
        <GridActionsCellItem
        label="تعديل"
        onClick={() => handleEditOpen(params.row)}
        showInMenu
      />,
        <GridActionsCellItem
          label="حذف"
          onClick={() => handleDeleteConfirmation(params.row)}
          showInMenu
        />,
        
      ],
    },
  ];

  // Handle delete confirmation
  const handleDeleteConfirmation = (album) => {
    setAlbumToDelete(album);
    setDeleteConfirmationOpen(true);
  };

  // Confirm delete action
  const handleDelete = async () => {
    if (albumToDelete) {
      try {
        await apis.deleteAlbum(albumToDelete.id);
        fetchAlbums();
        setDeleteConfirmationOpen(false);
        setAlbumToDelete(null);
      } catch (error) {
        console.error("Error deleting album:", error);
        setError("فشل حذف الألبوم");
      }
    }
  };

  // Close delete confirmation dialog
  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false);
    setAlbumToDelete(null);
  };



  const kidscolumns = [
    { field: "user_id", headerName: "ID", width: 70 },
    { field: "user_name", headerName: "اسم المستخدم", width: 200 },
    { field: "email", headerName: "البريد الإلكتروني", width: 250 },
    { field: "last_login", headerName: "آخر تسجيل دخول", width: 200 },
    {
      field: "kids",
      headerName: "الأطفال",
      width: 400,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            p: 1,
            border: "1px solid #ccc",
            borderRadius: "8px",
            margin:"10px"
          }}
        >
          {params.value.map((kid) => (
            <Box
              key={kid.kid_id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                borderBottom: "1px solid #eee",
              }}
            >
              <Typography variant="body2">{kid.kid_name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {kid.gender === "boy" ? "ولد" : "بنت"}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {kid.kid_dob}
              </Typography>
            </Box>
          ))}
        </Box>
      ),
    },
  ];



  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div dir="rtl" style={{ height: 600, width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        الألبومات
      </Typography>
      <Button
        onClick={handleAddOpen}
        variant="contained"
        color="primary"
        style={{ marginBottom: "20px" }}
      >
        إضافة ألبوم
      </Button>
      <DataGrid
        rows={albums}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        rowHeight={120}
        disableSelectionOnClick
        sx={{
          "& .MuiDataGrid-cell": {
            textAlign: "right",
            direction: "rtl",
          },
          "& .MuiDataGrid-columnHeader": {
            textAlign: "right",
            direction: "rtl",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      />
    <div dir="rtl" style={{ height: 600, width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        المستخدمون والأطفال
      </Typography>
      <DataGrid
        rows={users}
        columns={kidscolumns}
        pageSize={5}
        getRowHeight={()=>"auto"}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        getRowId={(row) => row.user_id}
        sx={{
          "& .MuiDataGrid-cell": {
            textAlign: "right",
          },
          "& .MuiDataGrid-columnHeader": {
            textAlign: "right",
          },
        }}
      />
    </div>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError("")}>
        <Alert onClose={() => setError("")} severity="error">
          {error}
        </Alert>
      </Snackbar>

      {/* Modal for editing */}
      <Dialog open={isEditModalOpen} onClose={handleEditClose}>
        <DialogTitle>تعديل الألبوم</DialogTitle>
        <DialogContent>
          {editingAlbum && (
            <>
              <TextField
                label="الاسم"
                fullWidth
                margin="normal"
                value={editingAlbum.name}
                onChange={(e) =>
                  setEditingAlbum((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              <TextField
                label="الألوان"
                fullWidth
                margin="normal"
                value={editingAlbum.color}
                onChange={(e) =>
                  setEditingAlbum((prev) => ({ ...prev, color: e.target.value }))
                }
              />
              <Box sx={{ textAlign: "center", marginTop: "10px" }}>
                <img
                  src={editingAlbum.image}
                  alt="Album"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    marginBottom: "10px",
                  }}
                />
                <ImageDropzone />
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">
            إلغاء
          </Button>
          <Button onClick={handleSave} color="primary">
            حفظ
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for adding new album */}
      <Dialog open={isAddModalOpen} onClose={handleAddClose}>
        <DialogTitle>إضافة ألبوم جديد</DialogTitle>
        <DialogContent>
          <TextField
            label="الاسم"
            fullWidth
            margin="normal"
            value={newAlbum.name}
            onChange={(e) =>
              setNewAlbum((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <TextField
            label="الألوان"
            fullWidth
            margin="normal"
            value={newAlbum.color}
            onChange={(e) =>
              setNewAlbum((prev) => ({ ...prev, color: e.target.value }))
            }
          />
          <Box sx={{ textAlign: "center", marginTop: "10px" }}>
            {newAlbum.image && (
              <img
                src={newAlbum.image}
                alt="Album"
                style={{
                  width: "200px",
                  height: "200px",
                  objectFit: "cover",
                  marginBottom: "10px",
                }}
              />
            )}
            <ImageDropzone />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClose} color="secondary">
            إلغاء
          </Button>
          <Button onClick={handleAddAlbum} color="primary">
            إضافة
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancel}>
        <DialogTitle>تأكيد الحذف</DialogTitle>
        <DialogContent>
          <Typography>هل أنت متأكد أنك تريد حذف هذا الألبوم؟</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            إلغاء
          </Button>
          <Button onClick={handleDelete} color="primary">
            حذف
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
};

export default Dashboard;
