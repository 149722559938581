import React, { useState, useEffect, useContext } from "react";
import { apis } from "../apis"; // Import your API functions
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Typography, CircularProgress } from "@mui/material";
import { FaLock } from "react-icons/fa"; // For the lock icon
import { useNavigate } from "react-router-dom"; // For navigation
import { useLocation } from "react-router-dom";
import { AuthContext } from "../AuthProvider";

const CheckOTPResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const { login } = useContext(AuthContext);

  const validationSchema = Yup.object({
    otp: Yup.string().required("رمز التحقق مطلوب").length(6, "يجب أن يكون رمز التحقق 6 أرقام"),
  });

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError("");

      try {
        const response = await apis.checkOtp(email, values.otp);

        if (response.status === "success") {
          localStorage.setItem("token", response.token);
          login(response.token);

          navigate("/reset-password");
        } else {
          setError(response.msg || "فشل في التحقق من رمز OTP.");
        }
      } catch (err) {
        setError("حدث خطأ أثناء التحقق.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }} dir="rtl">
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)" }}>
          <Typography variant="h4" align="center" gutterBottom>
            التحقق من رمز OTP
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              label="رمز التحقق"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
              InputProps={{ startAdornment: <FaLock style={{ marginRight: 10 }} /> }}
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              style={{ marginTop: "20px" }}
            >
              {loading ? <CircularProgress size={24} /> : "التحقق"}
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};


export default CheckOTPResetPassword;
